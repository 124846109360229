import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import KotlovskiTable from "../../components/tables/KotlovskiTable"
import Img1 from "../../images/hvtabla.jpg"
import Img2 from "../../images/hvkotur.jpg"
import Img3 from "../../images/hvtrake.jpg"
import HvTable from "../../components/tables/HladnovaljaniTabla"
import Head from "../../components/head"

const HladnoValjaniLimovi = () => {
  const title = "Hladnovaljani limovi"
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
  ]
  return (
    <Layout>
      <Head title={title} />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">{title}</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          {title}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>HLADNOVALJANI</strong> pljosnati proizvodi od
                niskougljeničnih čelika za hladno oblikovanje definisani
                standardom JUS EN 10130 pogodni su za zavarivanje i nanošenje
                prevlaka.
              </p>
              <br />
              <p>
                <strong>Asortiman:</strong>
                <br />
                Hladno valjani limovi isporučuju se kao:
              </p>
              <div className="columns is-multiline">
                <div className="column is-full">
                  <ul>
                    <li>
                      <b>Table</b>
                    </li>
                    <li>debljine od 0,40 - 3,00 mm</li>
                    <li>širine od 600 - 1500 mm</li>
                    <li>dužine od 1000 - 3000 mm</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <ul>
                    <li>
                      <b>Ulazne slitovane trake</b>
                    </li>
                    <li>debljine od 0,4 - 6 mm</li>
                    <li>širine od 30 - 1600 mm</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <ul>
                    <strong>Dozvoljena odstupanja:</strong>
                    <p>
                      Propisuje standard za tolerancije oblika i mera JUS EN
                      10131 za hladnovaljane pljosnate proizvode bez prevlake od
                      niskougljeničnog čelika i čelika sa visokim naponom
                      tečenja za hladno oblikovanje.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section className="tab-section">
            <div class="tabs">
              <ul>
                <li className="is-active is-size-3">
                  <a>Hemijski sastav</a>
                </li>
              </ul>
            </div>
            <HvTable />
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default HladnoValjaniLimovi
