import React from "react"

const HvTable = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <table className="table is-bordered is-fullwidth is-hoverable has-text-centered">
        <thead>
          <tr>
            <th colSpan={3} className="has-text-centered is-link">
              Standard za kvalitet
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EN 10027</td>
            <td>DIN 17155</td>
            <td>JUS C.B4.014</td>
          </tr>
          <tr>
            <td className="has-text-centered is-link" colSpan={3}>
              Oznaka vrste čelika
            </td>
          </tr>
          <tr>
            <td>DC01(FeP01)</td>
            <td>St 12</td>
            <td>Č 0146</td>
          </tr>
          <tr>
            <td>DC03(FeP03)</td>
            <td>RSt 13</td>
            <td>Č 0147 SU</td>
          </tr>
          <tr>
            <td>DC04(FeP04)</td>
            <td>St 14</td>
            <td>Č 0148 SU</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default HvTable
